var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form-wizard', {
    staticClass: "wizard-vertical mb-3",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "layout": "vertical",
      "finish-button-text": "Submit",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Account Details"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Account Details ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" Enter Your Account Details. ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username",
      "label-for": "v-username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-username",
      "placeholder": "johndoe"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "v-email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-email",
      "type": "email",
      "placeholder": "john.doe@email.com"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password",
      "label-for": "v-password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-password",
      "type": "password",
      "placeholder": "Password"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Confirm Password",
      "label-for": "v-c-password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-c-password",
      "type": "password",
      "placeholder": "Re-type Password"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Personal Info"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Personal Info ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Enter Your Personal Info.")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "First Name",
      "label-for": "v-first-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-first-name",
      "placeholder": "John"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Name",
      "label-for": "v-last-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-last-name",
      "placeholder": "Doe"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Country",
      "label-for": "v-country"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-country",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.countryName,
      "selectable": function selectable(option) {
        return !option.value.includes('select_value');
      },
      "label": "text"
    },
    model: {
      value: _vm.selectedContry,
      callback: function callback($$v) {
        _vm.selectedContry = $$v;
      },
      expression: "selectedContry"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "v-language",
      "label": "Language"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Language",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "selectable": function selectable(option) {
        return !option.value.includes('select_value');
      },
      "options": _vm.languageName,
      "label": "text"
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function callback($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Address"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Address ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Enter Your Address.")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "v-address"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-address",
      "placeholder": "98 Borough bridge Road, Birmingham"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Landmark",
      "label-for": "v-landmark"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-landmark",
      "placeholder": "Borough bridge"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pincode",
      "label-for": "v-pincode"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-pincode",
      "placeholder": "658921"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "v-city"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-city",
      "placeholder": "Birmingham"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Social Links"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Social Links ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Enter Your Social Links")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Twitter",
      "label-for": "v-twitter"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-twitter",
      "placeholder": "https://twitter.com/abc"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Facebook",
      "label-for": "v-facebook"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-facebook",
      "placeholder": "https://facebook.com/abc"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Google+",
      "label-for": "v-google-plus"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-google-plus",
      "placeholder": "https://plus.google.com/abc"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LinkedIn",
      "label-for": "v-linked-in"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-linked-in",
      "placeholder": "https://linkedin.com/abc"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }