var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('form-wizard-number'), _c('form-wizard-vertical'), _c('form-wizard-icon'), _c('form-wizard-vertical-icon')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }